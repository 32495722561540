.moduleWrapper {
  :global {
    .actionsSpace {
      display: flex;
      flex-direction: row !important;
      margin-bottom: 0 !important;
    }

    .itemWrapper {
      .ant-form-item-label label {
        width: 130px !important;
      }
    }

    .twoCol {
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 10px;

      .ant-form-item {
        margin-bottom: 0 !important;
        flex-grow: 1;
      }
    }

    .techStack  > div,
    .responsibility > div {
      padding-left: 60px !important;
      width: calc(100% - 60px) !important;
      display: flex;
      flex-direction: row !important;
      //gap: 0 !important;
      margin-bottom: 5px !important;

      .ant-form-item {
        margin-bottom: 0 !important;
      }
    }

    .threeCol {
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 10px;

      .ant-form-item {
        margin-bottom: 0 !important;
        flex-grow: 1;
      }
    }

    .btnCenter {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .skillItems {
      display: flex !important;
      flex-direction: row !important;
      align-items: center !important;
      margin-bottom: 5px !important;

      .ant-form-item {
        margin-bottom: 0;
      }
    }

    .skillCategory > div:nth-child(2){
      padding-left: 60px !important;
      width: auto !important;
    }

    .firstRow {
      align-items: center;
    }

    .itemWrapper {
      box-sizing: border-box;
      padding: 10px;
      background: #f4f4f4;
      border-radius: 5px;
    }

    .blockTitle {
      text-transform: uppercase;
      background: #ffc249;
      padding: 7px 10px;
      margin-bottom: 5px;
      border-radius: 5px;
    }

    .formArea {
      display :flex;
      flex-direction: column;
      gap: 0;
    }

    .row {
      display: flex;
      gap: 30px;
    }
    .left {
      width: 180px;
    }

    .ant-space {
      display: flex;
      margin-bottom: 30px;
      flex-direction: column;
    }

    .ant-form-item {
      margin-bottom: 5px;
    }

    .formArea {
      .ant-space-item {
        width: 100%;
      }
    }

    .right {
      width: calc(100% - 180px);
    }
  }
}
