.moduleWrapper {
  font-family: Tahoma, sans-serif;
  font-size: 16px;

  :global {
    .wrapper {
      height: 100vh;
      background: #ededed;
      display: flex;
      flex-direction: column;
    }

    .title {
      font-weight: 500;
      font-size: 24px;
    }

    .content {
      padding: 20px 20px 0 20px;
      border-radius: 10px;
      background: #FFF;
      width: 400px;
      margin-top: 50px !important;
      margin: 0 auto;
    }

    .header {
      box-sizing: border-box;
      padding: 5px 20px;
      width: 100%;
      background: rgba(3, 38, 60, 0.8980392157);
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #FFF;

      .logo, .right {
        width: 180px;
      }
    }

    .footer {
      width: 100%;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(3, 38, 60, 0.8980392157);
      color: #FFF;
      text-align: center;
      padding: 20px 10px;
    }
  }
}
