.ant-btn-primary {
    background-color: #ffc107 !important;
    color: #000 !important;
}
.ant-btn-primary:hover {
    background-color: #ffc107 !important;
    color: #000 !important;
    opacity: 0.8;
    border: 1px solid #000;
}
.ant-btn-primary {
    box-shadow: none !important;
}

html, body {
    padding: 0 0;
    margin: 0 0;
}

body .loadingOverlay {
    display: none;
}

body[loading=true] .loadingOverlay {
    width: 100%;
    height: 100%;
    background: #00000052;
    z-index: 11111111;
    position: fixed;
    display: block !important;
}

body[loading=true] .loadingOverlay .content {
    text-align: right;
    padding: 85px 25px 0 0;
}

.ant-upload-list-item {
    display: none;
}

.ant-upload-drag {
    height: 100px !important;
}

.ant-upload-list-item {
    display: none !important;
}

.previewArea {
    background: #f1f1f1;
    border-radius: 10px;
    margin-bottom: 10px;
}

.hiddenImageField .ant-form-item-control-input {
    display: none !important;
}

.ant-upload-text {
    padding: 0 20px;
}

form button[type="submit"] {
    margin-top: 10px;
}

.customScrollbar::-webkit-scrollbar-track
{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    /*border-radius: 10px;*/
    background-color: #afafaf;
}

.customScrollbar::-webkit-scrollbar
{
    width: 6px;
    background-color: #525252;
}

.customScrollbar::-webkit-scrollbar-thumb
{
    /*border-radius: 10px;*/
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: #555;
}

.ant-upload-drag-icon {
    margin: 0 0 !important;
}


.flexCenter {
    display: flex;
    gap: 10px;
    align-items: center;
}

table tr:hover {
    background-color: #eeeeee !important;
}
