:global {
  body[sidebar="close"] {
    .copyright {
      font-size: 12px !important;
    }

    .wrapper {
      .sidebar {
        width: 68px !important;

        .logo a {
          font-size: 15px;
        }

        .links {
          .name {
            display: none;
          }

          .link a {
            //display: flex;
            //gap: 10px;
            align-items: center;
            justify-content: center;
          }
        }
      }

      .content {
        width: calc(100% - 68px)
      }
    }
  }
}

.moduleWrapper {
  font-family: Tahoma, sans-serif;
  font-size: 16px;

  :global {
    .sidebarActions {
      cursor: pointer;

      div {
        display: flex;
      }
    }

    .wrapper {
      display: flex;
      flex-direction: row;
      height: 100vh;
      background: #f7f7f7;

      .sidebar {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        width: 180px;
        background: #2d2d2d;
        box-sizing: border-box;

        .copyright {
          width: 100%;
          text-align: center;
          font-size: 14px;
          padding: 10px 0;
          color: #FFF;
        }

        .logo {
          text-transform: uppercase;
          text-align: center;
          font-size: 20px;
          height: 44px;
          display: flex;
          background: #2d2d2d;
          justify-content: center;
          align-items: center;
          flex-direction: row;
          border-bottom: 1px solid #4f4f4f;

          a {
            display: flex;
            color: #FFF;
            text-decoration: none;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: 10px;
          }
        }

        .user {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          gap: 15px;
          padding: 15px 0px;

          .logout {
            cursor: pointer;
            display: flex;
          }
        }

        .links {
          display: flex;
          flex-direction: column;
          //gap: 10px;

          .link a {
            //border-top: 1px solid #b9b9b9;
            display: flex;
            gap: 10px;
            align-items: center;
            justify-content: flex-start;
            flex-direction: row;
            text-decoration: none;
            color: #fff;
          }

          .link a {
            padding: 15px 20px 15px 15px;
            cursor: pointer;
          }

          .link a.selected {
            //background: #dadada;
            //border-top: 1px solid #b9b9b9;
            //border-bottom: 1px solid #b9b9b9;
          }

          .link a.selected,
          .link a:hover {
            color: #FFF;
            background: #4f4f4f;
          }

          //.link:hover a {
          //  color: #000000;
          //}

        }
      }

      .content {
        width: calc(100% - 180px);

        .pageWrapper {
          height: calc(100vh - 44px);
          overflow: auto;
          background: #000;
        }

        .pageHeading {
          display: flex;
          font-size: 20px;
          padding: 10px 20px;
          background: #4f4f4f;
          color: #FFF;
          flex-direction: row;
          justify-content: space-between;

          .left, .right {
            width: 330px;
          }
          .right {
            cursor: pointer;
            display: flex;
            font-size: 15px;
            text-align: right;
            align-items: center;
            justify-content: flex-end;
            width: fit-content;
          }
          .pageTitle {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 15px;
            text-align: center;
          }
        }

        .noContent {
          width: 100%;
          height: 50px;
          text-align: center;
          background: #ededed;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .tableSearch {
          width: 100%;
          font-weight: 500;

          form {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            column-gap: 20px;
          }

          .submitRow {
            width: 100% !important;

            .ant-form-item-control-input-content {
              flex: auto;
              max-width: 100%;
              display: flex;
              align-items: center;
              justify-content: center;

              button {
                padding: 0 50px;
              }
            }
          }

          .threeColumns {
            .ant-form-item {
              width: calc(100%/3 - (2*20px)/3);
            }
          }
          .fourColumns {
            .ant-form-item {
              width: calc(100%/4 - (3*20px)/4);
            }
          }
        }

        .pageContent {
          height: calc(100% - 44px);
          overflow: auto;
          padding: 25px 30px;
          box-sizing: border-box;
          max-width: calc(100% - 40px);
          background: #fff;
          margin: 0 auto;
          margin-top: 20px;
          border-radius: 5px;
        }

        .pageContentFullWidth {
          height: calc(100% - 25px);
          overflow: auto;
          max-width: calc(100% - 25px);
          //padding: 15px 15px;
          background: #000;
          margin: 0 auto;
          margin-top: 15px;
          border-radius: 5px;
          box-sizing: border-box;
          color: #FFF;
        }
      }
    }
  }
}
